import React, { useState, useEffect } from "react";

import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import Login from "../pages/Login";

import UserMenu from "../partials/header/UserMenu";

import axios from "axios";

import { Link } from "react-router-dom";

import Barchart from "../charts/BarChart";
import Linechart from "../charts/LineChart";
import Smsalert from "./Smsalert";
import ProgressChart from "../charts/ProgressChart";
import PieChart from "../charts/PieChart";

function Dashboard() {
  
  //TODO: OFFSET VALUE
  const offset = 110;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isOn1, setIsOn1] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0],
  );

  

  const SendString = localStorage.getItem("sendmsg");
  const sendmsg = eval("(" + SendString + ")");
  // {"type":"command","id":1,"cmd":"mod_cmd","value":"070600210001"}
  let msgon = { type: "command", id: 1, cmd: "mod_cmd", value: "070600210001" };
  let msgoff = {
    type: "command",
    id: 1,
    cmd: "mod_cmd",
    value: "070600220001",
  };
  const [ison, setIson] = useState(false);

  let [resultlatest, setResultLatest] = useState([]);
  let [mqttdata, setMqttData] = useState([]);
  let [data1, setData1] = useState([]);

  let panelinfo = JSON.parse(localStorage.getItem("data"));
  // console.log(panelinfo);

  resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
  data1 = JSON.parse(localStorage.getItem("data1"));

  // console.log(resultlatest);

  // console.log(currentime);

  setInterval(() => {
    let resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    let data1 = JSON.parse(localStorage.getItem("data1"));
    setResultLatest(resultlatest);
    setMqttData(mqttdata);
    setData1(data1);
    //TODO:PUMP STATUS INDEX
    setIson(
      mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][offset + 32] === 1
          ? true
          : false
        : false,
    );
  }, 5000);
  // console.log(ison);

  const handleClick = () => {
    const confirmation = window.confirm(
      `Are you sure you want to switch ${
        ison === true ? "OFF" : "ON"
      } the pump?`,
    );
    if (confirmation) {
      const message = ison ? msgoff : msgon;
      sendmsg(panelinfo.imei, message);
    }

    console.log("reached at pump status!, button clicked");
  };

  // console.log(mqttdata[panelinfo.cardid] ? mqttdata[panelinfo.cardid] : "0");

  // console.log("imei: ", panelinfo.imei, " ", "deviceid", panelinfo.deviceid);

  // console.log(mqttdata);

  /**apis starts here - analytical data */
  let loginToken = JSON.parse(localStorage.getItem("loginToken"));
  // console.log(loginToken);

  const handleChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const headers = {
    // Authorization: `Bearer ${loginToken}`,
    Authorization: "Bearer" + " " + loginToken,
    "Content-Type": "application/json",
  };
  const data = {
    device_id: panelinfo.deviceid,
    // date: "2023-04-27",
    date: selectedDate,
  };
  // console.log(data);

  let [timeArray, setTimeArray] = useState([]);
  let time = [];
  let msgArray = [];
  let message = [];
  let dataArr = [];
  let [powerArr, setPowerArr] = useState([]);
  let [vAvg, setVavg] = useState([]);
  let [iAvg, setIAvg] = useState([]);
  let [EnergyArr, setEnergyArr] = useState([]);
  let [avgPfArr, setAvgPfArr] = useState([]);
  let [alertArr, setAlertArr] = useState([]);
  let [alertTime, setAlertTime] = useState([]);
  //TODOO:
  let [f1,setfl]=useState([]);

  let [ene, setene] = useState([]);

  function stringToArray(str) {
    const arr = str.split(",");
    return arr;
  }

  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString(),
  );

  function convertTo24Hour(timeString) {
    const time = new Date("1970-01-01 " + timeString);
    let hours = time.getHours();
    const isPM = hours >= 12;
    hours = (hours % 12) + (isPM ? 12 : 0);
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    const time24 =
      hours.toString().padStart(2, "0") + ":" + minutes + ":" + seconds;

    return time24;
  }
  // console.log(convertTo24Hour(currentTime));
  let currentime = convertTo24Hour(
    JSON.parse(localStorage.getItem("currentime")),
  );
  // console.log(currentime);

  useEffect(() => {
    axios
      .post(
        "https://sanlec.smart-iot.in/api/super-admin/device/getdevicelogs",
        data,
        { headers },
      )
      .then(function (response) {
        // console.log(response.data.data);
        let x = response.data.data;
        let log = x.logs;
        // console.log(log[0].log_time);
        // console.log(logs.length);
        timeArray = [];
        for (let i = 0; i < log.length; i++) {
          //accessing log_time from the log (api)
          let y = log[i].log_time;
          time = new Date(y).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Asia/Kolkata",
          });
          timeArray.push(convertTo24Hour(time));

          //accessing msg from the log (api)
          let w = log[i].msg;
          message.push(stringToArray(w));
          // console.log(message);

          //to decrpt
          let array_index = 0;
          msgArray = [];
          for (let index = 0; index < 200; index++) {
            array_index = index * 2;
            msgArray[index] =
              Number(message[i][array_index]) * 256 +
              Number(message[i][array_index + 1]);

            dataArr[i] = msgArray;
            // console.log(dataArr[i]);
          }
        }
        setTimeArray(timeArray);
        // console.log(timeArray);
        // console.log(dataArr);

        for (let i = 0; i < dataArr.length; i++) {
          avgPfArr[i] = dataArr[i][30] / 100;
          powerArr[i] = dataArr[i][14];
          vAvg[i] = dataArr[i][3];
          iAvg[i] = dataArr[i][11];
          EnergyArr[i] = dataArr[i][12] * 65536 + dataArr[i][13];
//TODOOOOO
          ene[i]  = dataArr[i][0]

          // alertArr[i] = [
          //   dataArr[i][61],
          //   dataArr[i][62],
          //   dataArr[i][63],
          //   dataArr[i][64],
          //   dataArr[i][65],
          //   dataArr[i][66],
          // ];
          alertArr[i] = [
            { vl1_fault: dataArr[i][61], time: timeArray[i] },
            { vl2_fault: dataArr[i][62], time: timeArray[i] },
            { vl3_fault: dataArr[i][63], time: timeArray[i] },
            { il1_fault: dataArr[i][64], time: timeArray[i] },
            { il2_fault: dataArr[i][65], time: timeArray[i] },
            { il3_fault: dataArr[i][66], time: timeArray[i] },
          ];

          // alertArr[i] = dataArr[i][61];
        }
        for(let i = 0; i < message.length; i++){
          f1[i] = ((Number(message[i][1190]) * 256 + Number(message[i][1191]))-660)*0.315;
          
        }
        setVavg(vAvg);
        setIAvg(iAvg);
        setPowerArr(powerArr);
        setEnergyArr(EnergyArr);
        setAvgPfArr(avgPfArr);
        setAlertArr(alertArr);
        //TODOO:
        setfl(f1);

        
        setene(ene);
        // console.log("hello from axios");
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [selectedDate, panelinfo.deviceid]);

  // console.log(fl);
  
  let extractedFaults = [];
  for (let i = 0; i < alertArr.length; i++) {
    const faultObj = alertArr[i];
    const time1 = faultObj[0].time;
    if (
      faultObj[0].vl1_fault === 1 ||
      faultObj[1].vl2_fault === 1 ||
      faultObj[2].vl3_fault === 1
    ) {
      extractedFaults.push({ fault: "under voltage fault", time1 });
    }
    if (
      faultObj[0].il1_fault === 1 ||
      faultObj[1].il2_fault === 1 ||
      faultObj[2].il3_fault === 1
    ) {
      extractedFaults.push({ fault: "under current fault", time1 });
    }
    if (
      faultObj[0].vl1_fault === 2 ||
      faultObj[1].vl2_fault === 2 ||
      faultObj[2].vl3_fault === 2
    ) {
      extractedFaults.push({ fault: "Over Voltage fault", time1 });
    }
    if (
      faultObj[0].il1_fault === 2 ||
      faultObj[1].il2_fault === 2 ||
      faultObj[2].il3_fault === 2
    ) {
      extractedFaults.push({ fault: "Over Current fault", time1 });
    }
    if (
      faultObj[0].vl1_fault === 3 ||
      faultObj[1].vl2_fault === 3 ||
      faultObj[2].vl3_fault === 3
    ) {
      extractedFaults.push({ fault: "Imbalance voltage fault", time1 });
    }
    if (
      faultObj[0].il1_fault === 3 ||
      faultObj[1].il2_fault === 3 ||
      faultObj[2].il3_fault === 3
    ) {
      extractedFaults.push({ fault: "Imbalance current fault", time1 });
    }
  }
  // console.log(extractedFaults);
  if (extractedFaults) {
    localStorage.setItem("extractedFaults", JSON.stringify(extractedFaults));
  }

  // let alertTextArr = [];
  // for (let i = 0; i < alertArr.length; i++) {
  //   for (let j = 0; j < 6; j++) {
  //     if (alertArr[i][j] === 1) {
  //       alertTextArr[i] = "under Voltage Fault";
  //       // console.log("under fault");

  //       alertTime[i] = timeArray[i];
  //       console.log("under voltage");
  //     }

  //     else if (alertArr[i][j] === 2) {
  //       alertTextArr[i] = "Over Voltage Fault";
  //       alertTime[i] = timeArray[i];
  //       console.log("over voltage");
  //     }

  //     else if (alertArr[i][j] === 3) {
  //       alertTextArr[i] = "Imbalance Voltage Fault";
  //       alertTime[i] = timeArray[i];
  //       console.log("imbalnce");
  //     }

  //   }
  // }
  // console.log(alertTextArr);
  // console.log(alertTime);

  // console.log(timeArray);
  // console.log(dataArr);
  // console.log(EnergyArr);
  // console.log(avgPfArr);
  // new optimized array
  let avgCvalueArray = [];
  let avgCtimeArray = [];

  let avgVvalueArray = [];
  let avgVtimeArray = [];

  let avgPFvalueArray = [];
  let avgPFtimeArray = [];

  let powervalueArray = [];
  let powertimeeArray = [];

  let energyvalueArray = [];
  let energytimeArray = [];

  //TODOO:
  let ene10mins = [];
  let time10mins = [];

  let f1mins =[];
  let f1time = [];

  // new optimized array
  for (let i = 0; i < timeArray.length / 30; i++) {
    let trueIndex = 0;
    for (let j = 0; j < 30; j++) {
      trueIndex = i * 30 + j;
      if (
        iAvg[trueIndex] - iAvg[trueIndex + 1] > 1 ||
        iAvg[trueIndex + 1] - iAvg[trueIndex] > 1
      ) {
        avgCvalueArray.push(iAvg[trueIndex]);
        avgCtimeArray.push(timeArray[trueIndex]);
      }
      if (
        vAvg[trueIndex] - vAvg[trueIndex + 1] > 1 ||
        vAvg[trueIndex + 1] - vAvg[trueIndex] > 1
      ) {
        avgVvalueArray.push(vAvg[trueIndex]);
        avgVtimeArray.push(timeArray[trueIndex]);
      }
      if (
        powerArr[trueIndex] - powerArr[trueIndex + 1] > 10 ||
        powerArr[trueIndex + 1] - powerArr[trueIndex] > 10
      ) {
        powervalueArray.push(powerArr[trueIndex]);
        powertimeeArray.push(timeArray[trueIndex]);
      }
      if (
        avgPfArr[trueIndex] - avgPfArr[trueIndex + 1] > 0.1 ||
        avgPfArr[trueIndex + 1] - avgPfArr[trueIndex] > 0.1
      ) {
        avgPFvalueArray.push(avgPfArr[trueIndex]);
        avgPFtimeArray.push(timeArray[trueIndex]);
      }
    }
    let trueIndex1 = i * 30 + 30;
    if (trueIndex1 == trueIndex) {
      trueIndex1++;
      // console.log("hellow from true");
    }
    if (trueIndex1 <= timeArray.length) {
      avgCvalueArray.push(iAvg[trueIndex1]);
      avgCtimeArray.push(timeArray[trueIndex1]);

      avgVvalueArray.push(vAvg[trueIndex1]);
      avgVtimeArray.push(timeArray[trueIndex1]);

      powervalueArray.push(powerArr[trueIndex1]);
      powertimeeArray.push(timeArray[trueIndex1]);

      avgPFvalueArray.push(avgPfArr[trueIndex1]);
      avgPFtimeArray.push(timeArray[trueIndex1]);


      //TODOOO:
      ene10mins.push(ene[trueIndex1]);
      time10mins.push(timeArray[trueIndex1]);

      f1mins.push(f1[trueIndex1]);
      f1time.push(timeArray[trueIndex1]);
    }
  }
  // console.log(ene10mins);
  // console.log(avgCvalueArray);
  // console.log(avgCtimeArray);

  let timeHour = [];
  let energyPerHour = [];

  for (let i = 0; i < EnergyArr.length - 1; i += 60) {
    const start = Math.max(0, i - 60);
    const end = Math.min(i + 60, EnergyArr.length - 1);
    energyPerHour.push(EnergyArr[end] - EnergyArr[start]);
    timeHour.push(timeArray[end]);
  }
  // console.log(timeHour);
  // console.log(energyPerHour);
  // console.log(mqttdata?.[panelinfo.cardid]?.[61]);
  // console.log(
  //   (mqttdata[panelinfo.cardid][12] * 65536 + mqttdata[panelinfo.cardid][13]) /
  //     1000
  // );

  // TIMER-1 PERCENTAGE
  let Timer1per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][40] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][51] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][40] === 0
          ? 1
          : mqttdata[panelinfo.cardid][40]
        : 1)) *
    100;
  // TIMER-2 PERCENTAGE
  let Timer2per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][41] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][52] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][41] === 0
          ? 1
          : mqttdata[panelinfo.cardid][41]
        : 1)) *
    100;
  // TIMER-3 PERCENTAGE
  let Timer3per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][42] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][53] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][42] === 0
          ? 1
          : mqttdata[panelinfo.cardid][42]
        : 1)) *
    100;
  // TIMER-4 PERCENTAGE
  let Timer4per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][43] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][54] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][43] === 0
          ? 1
          : mqttdata[panelinfo.cardid][43]
        : 1)) *
    100;
  // TIMER-5 PERCENTAGE
  let Timer5per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][44] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][55] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][44] === 0
          ? 1
          : mqttdata[panelinfo.cardid][44]
        : 1)) *
    100;

  // TIMER-6 PERCENTAGE
  let Timer6per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][45] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][56] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][45] === 0
          ? 1
          : mqttdata[panelinfo.cardid][45]
        : 1)) *
    100;
  // TIMER-7 PERCENTAGE
  let Timer7per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][46] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][57] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][46] === 0
          ? 1
          : mqttdata[panelinfo.cardid][46]
        : 1)) *
    100;
  // TIMER-8 PERCENTAGE
  let Timer8per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][47] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][58] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][47] === 0
          ? 1
          : mqttdata[panelinfo.cardid][47]
        : 1)) *
    100;
  // TIMER-9 PERCENTAGE
  let Timer9per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][48] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][59] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][48] === 0
          ? 1
          : mqttdata[panelinfo.cardid][48]
        : 1)) *
    100;
  // TIMER-10 PERCENTAGE
  let Timer10per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][49] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][60] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][49] === 0
          ? 1
          : mqttdata[panelinfo.cardid][49]
        : 1)) *
    100;

  // console.log(Timer1per);
  // console.log(Timer2per);
  // console.log(Timer3per);
  // console.log(Timer4per);
  // console.log(Timer5per);
  // console.log(Timer6per);
  // console.log(Timer7per);
  // console.log(Timer8per);
  // console.log(Timer9per);
  // console.log(Timer10per);
  let realCurrentIL1 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][8] + 0.1
    : 0.1;
  let realCurrentIL2 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][9] + 0.1
    : 0.1;
  let realCurrentIL3 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][10] + 0.1
    : 0.1;
  let realVoltageVL1 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][0]
    : 1;
  let realVoltageVL2 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][1]
    : 1;
  let realVoltageVL3 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][2]
    : 1;
  let realpf1 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][27] / 100
    : 1;
  let realpf2 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][28] / 100
    : 1;
  let realpf3 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][29] / 100
    : 1;

  //TODOO:Level
  
 

  let level = (Number(data1?.[1190]) * 256 + Number(data1?.[1191]) - 660)*0.315;

  let runtime = mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][30] * 60 +
                          mqttdata[panelinfo.cardid][31]
                        : 0
  //  console.log(runtime);                     
  // console.log(data1)
  // console.log(level)
  

  return (
    <div className="flex h-screen overflow-hidden bg-green-400">
      {/* Content area */}

      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
        <header className="sticky top-0 z-30 bg-green-800 p-1  flex flex-wrap justify-end items-center gap-2 ">
          <div className="grid justify-start grid-flow-col gap-2 sm:auto-cols-max sm:justify-end">
            <label htmlFor="datepicker" className="sr-only">
              Select Date
            </label>
            <input
              type="date"
              id="datepicker"
              value={selectedDate}
              onChange={handleChange}
              className="px-3 py-2 mt-2 border rounded-lg sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60"
            />
          </div>
          {/* <div>
            <Link to="/Table">
              <button className="bg-white text-black shadow-gray-600 shadow-lg  px-3 py-2 mt-2 border rounded-lg sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60">
                Configuration
              </button>
            </Link>
          </div> */}
          <div className="p-1">
            <UserMenu />
          </div>
        </header>

        <main className="p-0 m-0 ">
          <div className="w-full p-2 py-2 m-auto mb-2 sm:px-6 lg:px-8">
            {/* Welcome banner */}
            {/* <button>Latest Data Arrival Time: {currentime}</button> */}
            <WelcomeBanner />
            
            <div className="bg-white rounded-lg shadow-md p-4 mb-2 flex flex-col flex-wrap bg-opacity-70 backdrop-filter backdrop-blur-lg font-sans">
              {/* fuel tank container */}
              <div className="bg-gray-300 h-32 sm:h-40 md:h-48 lg:h-56 xl:h-64 w-2/3 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 mx-auto rounded-md overflow-hidden mb-4 relative">
                {/* fuel level */}
                <div
                  className="absolute bottom-0 left-0 right-0 h-full bg-green-500"
                  style={{ height: `${(level / 1000) * 100}%` }}>
                    
                  </div>
              </div>
              {/* Display the fuel level in liters */}
              <p className="text-gray-800 text-center text-bold">{level} L Fuel Level</p>
            </div>
            {/* //value Cards  */}
            <div className="bg-white rounded-lg shadow-md p-4 mb-2 flex flex-col flex-wrap bg-opacity-70 backdrop-filter backdrop-blur-lg ">
              {/* voltage & phase voltage divs - 8 */}
              <div className="flex flex-wrap ">
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150  ">
                  <div className="font-bold">
                    VL<sub>1-2</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][14]
                        : 0}
                      V
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-yellow-500  to-yellow-400 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    VL<sub>2-3</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][16]
                        : 0}
                      V
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    VL<sub>3-1</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][18]
                        : 0}
                      V
                    </span>
                  </div>
                </div>

                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    V<sub>L1-L2</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][4]
                        : 0}
                      V
                    </span>
                  </div>
                </div> */}

                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-yellow-500  to-yellow-400 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    V<sub>L2-L3</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][5]
                        : 0}
                      V
                    </span>
                  </div>
                </div> */}

                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    V<sub>L3-L1</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][6]
                        : 0}
                      V
                    </span>
                  </div>
                </div> */}
              </div>

              {/* imb voltage and pf div -6 */}
              <div className="flex flex-wrap  ">
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl    f fle btn hover:bg-indigo p-1">
                  <div className="font-bold">
                    IMB<sub>L1-L2</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][offset + 6]
                        : 0}
                      V
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-yellow-500  to-yellow-400 hover:shadow-xl    f fle btn hover:bg-indigo p-1">
                  <div className="font-bold">
                    IMB<sub>L2-L3</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][offset + 7]
                        : 0}
                      V
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl   f fle btn  hover:bg-indigo p-1">
                  <div className="font-bold">
                    IMB<sub>L3-L1</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][offset + 8]
                        : 0}
                      V
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl   f fle btn  hover:bg-indigo p-1">
                  <div className="font-bold">
                    PF<sub>1</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][27] / 100
                        : 0}
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-yellow-500  to-yellow-400 hover:shadow-xl   f fle btn  hover:bg-indigo p-1">
                  <div className="font-bold">
                    PF<sub>2</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][28] / 100
                        : 0}
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl   f fle btn  hover:bg-indigo p-1">
                  <div className="font-bold ">
                    PF<sub>3</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][29] / 100
                        : 0}
                    </span>
                  </div>
                </div> */}
              </div>

              {/* current & imbalance current & running power div -8*/}
              <div className="flex flex-wrap  ">
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    IL<sub>1</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][22]
                        : 0}
                      A
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-yellow-500  to-yellow-400 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    IL<sub>2</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][24]
                        : 0}
                      A
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    IL<sub>3</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][26]
                        : 0}
                      A
                    </span>
                  </div>
                </div>

                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    IMB<sub>IL1-IL2</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][9]
                        : 0}
                      A
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-yellow-500  to-yellow-400 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    IMB
                    <sub>IL2-IL3</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][10]
                        : 0}
                      A
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    IMB<sub>IL3-IL1</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][11]
                        : 0}
                      A
                    </span>
                  </div>
                </div> */}
              </div>
              {/* extra info cards */}
              <div className="flex flex-wrap  ">
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-purple-600 to-purple-700 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold text-center">TOTAL POWER</div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][offset + 62] / 100
                        : 0}
                      KW
                    </span>
                  </div>
                </div> */}
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-purple-600 to-purple-700 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold text-center">TOTAL ENERGY
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][0]) 
                        : 0}
                      Kwh
                    </span>
                  </div>
                </div>

               
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-purple-600 to-purple-700 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold text-center">TOTAL RUNTIME</div>
                  <div>
                    
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][60] * 60 +
                          mqttdata[panelinfo.cardid][61]
                        : 0}
                        {/* {runtime} */}
                      mins
                    </span>
                  </div>
                </div>
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-purple-600 to-purple-700 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    VL<sub>AVG</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][3]
                        : 0}
                      V
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-purple-600 to-purple-700 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    V<sub>L-Lavg</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][7]
                        : 0}
                      V
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-gradient-to-br from-purple-600 to-purple-700 hover:shadow-xl  f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    IL<sub>avg</sub>
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][11]
                        : 0}
                      A
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
            {/* value ends here */}
            {/* chart Cards */}
            <div className="flex mb-2 justify-center items-center gap-3 flex-wrap ">
              {/* <div className="bg-gray-200 flex-1  p-5  shadow-indigo-500/50  my-2 rounded-lg shadow-lg ">
                <div className="text-center font-bold text-sm font-sans p-1">
                  REAL TIME 3-PHASE VOLTAGE
                </div>
                <PieChart
                  value1Label="VL1"
                  value2Label="VL2"
                  value3Label="VL3"
                  tag="VOLTAGE"
                  value1={realVoltageVL1}
                  value2={realVoltageVL2}
                  value3={realVoltageVL3}
                />
              </div> */}
              {/* <div className="bg-gray-200 flex-1  p-5  shadow-indigo-500/50  my-2 rounded-lg shadow-lg ">
                <div className="text-center font-bold text-sm font-sans p-1">
                  REAL TIME 3-PHASE CURRENT
                </div>
                <PieChart
                  value1Label="IL1"
                  value2Label="IL2"
                  value3Label="IL3"
                  tag="CURRENT"
                  value1={realCurrentIL1}
                  value2={realCurrentIL2}
                  value3={realCurrentIL3}
                />
              </div> */}
              {/* <div className="bg-gray-200 flex-1  p-5  shadow-indigo-500/50  my-2 rounded-lg shadow-lg ">
                <div className="text-center font-bold text-sm font-sans p-1">
                  REAL TIME 3-PHASE POWER FACTOR
                </div>
                <PieChart
                  value1Label="PF1"
                  value2Label="PF2"
                  value3Label="PF3"
                  tag="POWER FACTOR"
                  value1={realpf1}
                  value2={realpf2}
                  value3={realpf3}
                />
              </div> */}
            </div>
            {/* <div className="bg-gray-200  p-5  shadow-indigo-500/50  my-2 rounded-lg shadow-lg ">
              <div className="text-center font-bold text-lg font-sans p-1">
                REAL TIME TIMER STATUS
              </div>
              <ProgressChart
                per1={Timer1per}
                per2={Timer2per}
                per3={Timer3per}
                per4={Timer4per}
                per5={Timer5per}
                per6={Timer6per}
                per7={Timer7per}
                per8={Timer8per}
                per9={Timer9per}
                per10={Timer10per}
              />
            </div> */}
            
            <div className="flex mb-2 flex-wrap justify-center items-center gap-3">
              {/* <div className="flex-1 bg-gray-200  p-5  shadow-indigo-500/50  my-2 rounded-lg shadow-lg ">
                <div className="text-center font-bold text-xl font-serif p-2">
                  Power<sub></sub> Trend
                </div>
                <Linechart
                  dataArr={powervalueArray}
                  timeArr={powertimeeArray}
                  divider={1}
                  tag="Power"
                  colour="rgb(138,43,226)"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div> */}
              <div className="flex-1 bg-gray-200  p-5  shadow-green-500/50  my-2 rounded-lg shadow-lg ">
                <div className="text-center font-bold text-xl font-serif p-2">
                  Fuel Trend
                </div>
                <Linechart
                  dataArr={f1mins}
                  timeArr={f1time}
                  divider={1}
                  tag="Fuel level Trend"
                  colour="rgb(138,43,226)"
                  min={0}
                  max={1}
                  date={selectedDate}
                />
              </div>
            </div>
            <div className="flex mb-2 flex-wrap justify-center items-center gap-3">
              <div className="flex-1 bg-gray-200  p-5  shadow-gren-500/50  my-2 rounded-lg shadow-lg ">
                <div className="text-center font-bold text-xl font-serif p-2">
                  Energy<sub></sub> Trend
                </div>
                <Barchart
                //TODOOO:
                  dataArr={ene10mins}
                  timeArr={time10mins}
                  divider={1}
                  tag="Energy"
                  colour="rgb(138,43,226)"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div>
            </div>
            {/* charts end here */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
