import React, { useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useState } from "react";
import Smsalert from "./Smsalert";

const Home = () => {
  //TODO:OFFSET
  const offset = 110;
  const cards = [];
  const options = [];

  let [mqttdata, setMqttData] = useState([]);
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

  setInterval(() => {
    // useEffect(() => {
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    setMqttData(mqttdata);
    // }, [mqttdata]);
  }, 5000);

  // console.log(mqttdata);

  let imeilist = JSON.parse(localStorage.getItem("imeilist"));
  // console.log(imeilist);

  let device_ids = JSON.parse(localStorage.getItem("device_ids"));
  // console.log(device_ids);

  let location_list = JSON.parse(localStorage.getItem("location_list"));

  let name_list = JSON.parse(localStorage.getItem("name_list"));

  function handleClick(imei, deviceid, cardid) {
    const data = { imei, deviceid, cardid };
    localStorage.setItem("data", JSON.stringify(data));
  }

  for (let i = 0; i < imeilist.length; i++) {
    options.push({
      value: i,
      label: `Device ${i + 1}`,
    });
    cards.push(
      //   <Link to="/Dashboard">
      <div
        key={i}
        id={`card${i}`}
        className=" w-auto h-auto sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5 sm:h-1/3 md:h-1/3 lg:h-1/3 xl:h-1/5 2xl:h-1/4 2xl:w-1/6 3xl:h-1/4 3xl:w-1/6 p-5 mt-2 shadow-cyan-600 ease-in hover:shadow-4xl transition-shadow hover:shadow-green-900 
    max-w-sm max-h-full rounded-lg overflow-hidden shadow-lg m-2 cursor-pointer flex-grow bg-white bg-opacity-25 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50">
        <div className="border-b-4 border-blue-950 mb-2">
          <Link
            to="/Dashboard"
            onClick={() => handleClick(imeilist[i], device_ids[i], i)}>
            {/* <div className="text-center font-semibold font-mono text-xl pb-3 text-white">
              {name_list[i]}
            </div> */}
            <div className="text-center font-semibold font-mono text-xl pb-3 text-white">
              {location_list[i]}
            </div>
            <h3 className="text-center font-semibold font-mono text-xl pb-3 text-white">
              ({imeilist[i]})
            </h3>
          </Link>
        </div>

        <div className="flex flex-wrap justify-center items-center">
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl  border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm ">
            VL<sub>1</sub>
            <p className="p-0.5 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-lg shadow-lg hover:shadow-indigo-500/30 border border-red-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm ">
              {mqttdata?.[i] ? mqttdata[i][14] : 0} V
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            VL<sub>2</sub>{" "}
            <p className="p-0.5 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-lg shadow-xl border border-yellow-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm ">
              {mqttdata?.[i] ? mqttdata[i][16] : 0} V
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            VL<sub>3</sub>{" "}
            <p className="p-0.5 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-lg shadow-xl border border-indigo-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][18] : 0} V
            </p>
          </p>
        </div>

        <div className="flex flex-wrap justify-center items-center ">
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            IL<sub>1</sub>{" "}
            <p className="p-0.5 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-lg shadow-xl border border-red-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][22] : 0} A
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            IL<sub>2</sub>{" "}
            <p className="p-0.5 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-lg shadow-xl border border-yellow-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][24] : 0} A
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            IL<sub>3</sub>{" "}
            <p className="p-0.5 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-lg shadow-xl border border-blue-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm ">
              {mqttdata?.[i] ? mqttdata[i][26] : 0} A
            </p>
          </p>
        </div>

        <div className="flex flex-wrap">
          {/* <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            Power{" "}
            <p className="p-0.5 m-1 bg-green-500 hover:bg-green-600 hover:text-white rounded-lg shadow-xl border border-green-600 hover:border-green-700 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][offset + 62] / 1000 : 0} KW
            </p>
          </p> */}
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            Energy{" "}
            <p className="p-0.5 m-1 bg-green-500 hover:bg-green-600 hover:text-white rounded-lg  shadow-xl border border-green-600 hover:border-green-700 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-sm">
              {mqttdata?.[i]
                ? (mqttdata[i][0])
                : 0}{" "}
              KWh
            </p>
          </p>
        </div>

        {/* <div className="flex flex-wrap">
          <p
            className={`flex flex-col items-center flex-1 p-1 m-1 justify-center  bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center text-sm `}>
            MOTOR STATUS
            <button
              className={`flex items-center flex-1 p-2 m-1 justify-center rounded-lg shadow-md ${
                (mqttdata?.[i] ? mqttdata[i][offset + 32] : 0 === 1)
                  ? "bg-green-400 text-white hover:bg-green-500"
                  : "bg-red-500 text-white hover:bg-red-600"
              } transition-colors ease-in-out duration-200`}>
              {(mqttdata?.[i] ? mqttdata[i][32] : 0 === 1) ? "ON" : "OFF"}
            </button>
          </p>
        </div> */}
      </div>,
    );
  }

  function scrolltoCard(value) {
    const element = document.getElementById(`card${value}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleChange = (selectedOption) => {
    // selectedOption.scrollIntoView();
    let cardvalue = selectedOption.value;
    scrolltoCard(cardvalue);
    // cardno.scrollIntoView();
    console.log(selectedOption);
  };

  return (
    <div className="mt-0  ">
      {/* <div className="flex flex-wrap"></div> */}
      <div className="sticky top-0 z-50 flex p-2 bg-green-800 text-center font-sans font-semibold  justify-center flex-wrap gap-6">
        <nav className="text-white text-xl">
          DIESEL DENSET MONITORING SYSTEM
        </nav>
        <div className="">
          <Select
            options={options}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="min-h-screen  bg-gradient-to-br from-green-700 via-indigo-400 to-green-400 overflow-hidden">
        <div className="flex flex-wrap min-w-fit">{cards}</div>
      </div>

      {/* <div>
        <Smsalert />
      </div> */}
    </div>
  );
};

export default Home;
